import { Controller } from '@hotwired/stimulus';

import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

export default class extends Controller {
  static targets = ['anchor', 'tooltip'];

  tooltip = null;

  connect() {
    const trigger = this.data.get('trigger') || 'mouseenter focus';
    const placement = this.data.get('placement') || 'top';

    let anchor;
    if (this.hasAnchorTarget) {
      anchor = this.anchorTarget;
    } else {
      anchor = this.element;
    }

    this.tooltip = tippy(anchor, {
      placement,
      content: () => {
        if (this.hasTooltipTarget) {
          const content = (this.tooltipTarget.innerHTML || '').trim();
          console.log(content.length);
          if (content) {
            return content;
          }
        }
        return this.data.get('content');
      },
      trigger,
      arrow: true,
      allowHTML: true,
      interactive: true,
    });
  }

  onClick(e) {
    e.preventDefault();
    if (this.tooltip) {
      console.log('show');
      this.tooltip.show();
    }
  }
}
