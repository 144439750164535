window.sparkxyz = window.sparkxyz || {};
(function (sparkxyz) {
  var page = sparkxyz.page;
  var recruitingPage;
  var ERROR_COLOR = '#f04124';
  var RecruitingAPI = sparkxyz.API.recruiting;

  if (
    !(page.controller === 'position_applications' && page.action === 'index')
  ) {
    return;
  }
  function sendEmail(props) {
    var message = props.message;
    var projectId = props.projectId;
    var profileId = props.profileId;
    var projectPositionId = props.projectPositionId;

    var url = '/projects/' + projectId + '/email_applicant';
    var data = {
      message: message,
      project_position_id: projectPositionId,
      profile_id: profileId,
    };
    return $.ajax({
      url: url,
      method: 'POST',
      data: data,
    });
  }
  function updateInterviewRound(data) {
    var url =
      '/projects/' +
      data.projectId +
      '/position_applications/' +
      data.applicationId;

    return $.ajax(url, {
      method: 'PUT',
      data: {
        position_application: {
          interview_round: data.round,
        },
      },
    });
  }

  function deleteApplication(data) {
    var url =
      '/projects/' +
      data.projectId +
      '/position_applications/' +
      data.applicationId;
    return $.ajax(url, {
      method: 'DELETE',
    });
  }

  function showMessage(text, type) {
    var options = {
      text: text,
    };
    if (type && type === 'error') {
      options.textColor = ERROR_COLOR;
    }
    Snackbar.show(options);
  }

  function ApplicationCard(options) {
    this.$el = options.el;
  }

  ApplicationCard.prototype.updateRound = function (round) {
    this.$el.data('round', round);
  };

  ApplicationCard.prototype.updateEl = function ($el) {
    this.$el = $el;
  };

  ApplicationCard.prototype.getData = function () {
    var $el = this.$el;
    var data = {
      applicationId: $el.data('application-id'),
      round: parseInt($el.data('round') || 0, 10),
      projectId: $el.data('project-id'),
      profileId: $el.data('profile-id'),
      name: this.$el.data('name'),
      projectPositionId: $el.data('project-position-id'),
    };
    return data;
  };

  function ProjectInterviewRound(options) {
    this.$el = options.el;
    this.$inputGroup = this.$el.find('.form-group');
    this.$heading = this.$el.find('.heading');
    this.$headerText = this.$el.find('.round-name');
    this.$inputField = this.$el.find('.js-round-field');
  }

  ProjectInterviewRound.prototype.getRoundNameFieldValue = function () {
    return this.$inputField.val();
  };

  ProjectInterviewRound.prototype.getProjectId = function () {
    return this.$el.data('project-id');
  };

  ProjectInterviewRound.prototype.getName = function () {
    return this.$el.data('name');
  };

  ProjectInterviewRound.prototype.getRound = function () {
    return parseInt(this.$el.data('round'), 10);
  };

  ProjectInterviewRound.prototype.getId = function () {
    return parseInt(this.$el.data('id'), 10);
  };

  ProjectInterviewRound.prototype.getData = function () {
    return {
      id: this.getId(),
      project_id: this.getProjectId(),
      name: this.getName(),
      round: this.getRound(),
    };
  };

  ProjectInterviewRound.prototype.setRound = function (round) {
    this.$el.data('round', round);
  };

  ProjectInterviewRound.prototype.setName = function (name) {
    this.$el.data('name', name);
  };

  ProjectInterviewRound.prototype.isFirstRound = function () {
    return this.getRound() === 0;
  };

  ProjectInterviewRound.prototype.isLastRound = function () {
    return this.$el.next().length === 0;
  };

  ProjectInterviewRound.prototype.updateCards = function () {
    this.$el
      .find('.application-group__list')
      .children(':not(.application-card--empty)')
      .each(
        function (i, el) {
          this.updateCardState($(el), {
            isFirstRound: this.isFirstRound(),
            isLastRound: this.isLastRound(),
          });
        }.bind(this),
      );
  };
  ProjectInterviewRound.prototype.updateCardState = function ($card, metadata) {
    var $promoteButton = $card.find('.promote-button');
    var $depromoteButton = $card.find('.depromote-button');
    if (metadata.isFirstRound) {
      $depromoteButton.addClass('hidden');
    } else if (metadata.isLastRound) {
      $promoteButton.addClass('hidden');
    } else {
      $promoteButton.removeClass('hidden');
      $depromoteButton.removeClass('hidden');
    }
  };

  ProjectInterviewRound.prototype.updateRound = function (round) {
    this.setRound(round);
    this.updateCards();
  };

  ProjectInterviewRound.prototype.renderRoundName = function (name) {
    this.$inputField.val(name);
    this.$headerText.text(name);
  };
  ProjectInterviewRound.prototype.resetFields = function () {
    this.renderRoundName(this.getName());
    this.hideRoundEdit();
  };

  ProjectInterviewRound.prototype.updateName = function (name) {
    this.setName(name);
    this.renderRoundName(name);
    this.hideRoundEdit();
  };

  ProjectInterviewRound.prototype.hideRoundEdit = function () {
    this.$inputGroup.toggleClass('hidden');
    this.$heading.toggleClass('hidden');
  };

  var DeleteApplicationModal = (function () {
    var id = '#delete-application-modal';
    var $el;
    var options = {};
    var form = {
      data: {},
    };

    return {
      init: init,
      open: open,
      close: close,
      update: update,
    };

    function onConfirmDelete(e) {
      e.preventDefault();
      var data = form.data;
      if (!data.projectId || !data.applicationId) {
        return;
      }

      RecruitingAPI.deleteApplication(data)
        .done(function () {
          if (options.onDeleteApplicationSuccess) {
            options.onDeleteApplicationSuccess({
              id: data.applicationId,
            });
          }
        })
        .fail(function (err) {
          if (options.onDeleteApplicationFail) {
            options.onDeleteApplicationFail({
              error: err,
              id: data.id,
            });
          }
        });
    }

    function onClose() {
      form.data = {};
    }

    function open() {
      $el.foundation('open');
    }

    function close() {
      $el.foundation('close');
    }

    function update(data) {
      $el.find('#delete-applicant-name').html(data.name);
      form.data = data;
    }

    function init(props) {
      options = props || {};

      $el = $(id);
      $el.on('click', '#confirm-delete-application-button', onConfirmDelete);
      $el.on('closed.zf.reveal', onClose);
    }
  })();

  var EmailModal = (function () {
    var id = '#email-applicant-modal';
    var $el;
    var form = {
      $message: null,
      $projectId: null,
      $projectPositionId: null,
      $profileId: null,
    };

    return {
      init: init,
      open: open,
      close: close,
      update: update,
    };

    function onSendEmail(e) {
      e.preventDefault();
      var message = form.$message.val();
      var projectId = form.$projectId.val();
      var profileId = form.$profileId.val();
      var projectPositionId = form.$projectPositionId.val();

      RecruitingAPI.sendEmail({
        projectId: projectId,
        profileId: profileId,
        projectPositionId: projectPositionId,
        message: message,
      })
        .done(function (res) {
          showMessage(res.notice);
        })
        .fail(function (err) {
          var error = JSON.parse(err.responseText).error;
          showMessage(error, 'error');
        });

      close();
    }

    function open() {
      $el.foundation('open');
    }

    function close() {
      $el.foundation('close');
    }

    function onClose() {
      form.$projectId.val('');
      form.$projectPositionId.val('');
      form.$profileId.val('');
      form.$name.val('');
      form.$message.val('');
    }

    function update(data) {
      form.$projectId.val(data.projectId);
      form.$projectPositionId.val(data.projectPositionId);
      form.$profileId.val(data.profileId);
      form.$name.html(data.name);
    }

    function init() {
      $el = $(id);
      form.$projectId = $('#applicant-project-id');
      form.$profileId = $('#applicant-profile-id');
      form.$projectPositionId = $('#applicant-project-position-id');
      form.$name = $('#applicant-name');
      form.$message = $('#applicant-message');
      $el.on('click', 'button', onSendEmail);
      $el.on('closed.zf.reveal', onClose);
    }
  })();

  function RecruitingPage() {
    this.container = '.recruiting';
    this.cards = {};
    this.rounds = {};
  }

  RecruitingPage.prototype.updateApplicationRound = function ($card, action) {
    var $currentApplicationsList = $card.closest('.application-group__list');
    var $currentRound = $currentApplicationsList.closest('.application-group');
    var $targetRound;

    if (action === 'promote') {
      $targetRound = $currentRound.next('.application-group');
    } else {
      $targetRound = $currentRound.prev('.application-group');
    }
    var targetRoundId = this.createInterviewRound($targetRound);
    if ($targetRound.length === 0) {
      return;
    }

    var $applicationsList = $targetRound.find('.application-group__list');
    var $currentListEmptyState;
    $applicationsList.find('.application-card--empty').addClass('hidden');
    $card.appendTo($applicationsList);

    $('html,body').animate(
      {
        scrollTop: $targetRound.offset().top,
      },
      300,
    );

    // the empty state is hidden by default
    $currentListEmptyState = $currentApplicationsList.find(
      '.application-card--empty',
    );
    if ($currentApplicationsList.children().length === 1) {
      $currentListEmptyState.removeClass('hidden');
    }
    var info = {
      isFirstRound: this.rounds[targetRoundId].isFirstRound(),
      isLastRound: this.rounds[targetRoundId].isLastRound(),
    };
    this.rounds[targetRoundId].updateCardState($card, info);
  };

  RecruitingPage.prototype.onInterviewRoundChange = function (e) {
    var $el = $(e.target);
    // var applicationId = $el.data('application-id');
    var card = this.getCard($el);
    var applicationData = card.getData();
    var $groupContainer = $el.closest('.application-group');
    var interviewRoundId = this.createInterviewRound($groupContainer);
    var round = this.rounds[interviewRoundId].getRound();
    var applicationId = applicationData.applicationId;
    var projectId = applicationData.projectId;
    var action = $el.data('recruiting-action');
    var newRound = action === 'depromote' ? round - 1 : round + 1;

    $el.prop('disabled', true);
    RecruitingAPI.updateInterviewRound({
      action: action,
      applicationId: applicationId,
      round: newRound,
      projectId: projectId,
    }).done(
      function () {
        var $card = $el.closest('.application-card');
        this.cards[applicationId].updateRound(newRound);
        this.updateApplicationRound($card, action, newRound);
        $el.prop('disabled', false);
        this.refreshCharts();
      }.bind(this),
    );
  };

  RecruitingPage.prototype.setupEmailButton = function () {
    this.$container.on(
      'click',
      '.email-button',
      this.onOpenEmailModal.bind(this),
    );
  };

  RecruitingPage.prototype.setupDeleteApplicationButton = function () {
    this.$container.on(
      'click',
      '.delete-application-button',
      this.onOpenDeleteApplicationModal.bind(this),
    );
  };

  RecruitingPage.prototype.setupInterviewRoundButton = function () {
    this.$container.on(
      'click',
      '.interview-round-button',
      this.onInterviewRoundChange.bind(this),
    );
  };

  RecruitingPage.prototype.setupVentureDropdown = function () {
    $('#venture-dropdown').on('change', function () {
      var href = $(this).val();
      window.location.href = href;
    });
  };

  RecruitingPage.prototype.getCard = function ($el) {
    var $card = $el.closest('.application-card');
    var applicationId = $card.data('application-id');
    if (!this.cards.hasOwnProperty(applicationId)) {
      this.cards[applicationId] = new ApplicationCard({ el: $card });
    }
    return this.cards[applicationId];
  };

  RecruitingPage.prototype.onOpenDeleteApplicationModal = function (e) {
    e.preventDefault();
    var $el = $(e.target);
    var card = this.getCard($el);
    DeleteApplicationModal.update(card.getData());
    DeleteApplicationModal.open();
  };

  RecruitingPage.prototype.onOpenEmailModal = function (e) {
    e.preventDefault();
    var $el = $(e.target);
    var card = this.getCard($el);
    EmailModal.update(card.getData());
    EmailModal.open();
  };

  RecruitingPage.prototype.handleDeleteApplicationSuccess = function (data) {
    var $el;
    DeleteApplicationModal.close();
    showMessage('The application has been deleted successfully');
    this.refreshCharts();
    $el = $('#application-' + data.id);
    $el.fadeOut(300, function () {
      $el.remove();
    });
  };

  RecruitingPage.prototype.handleDeleteApplicationFail = function () {
    showMessage('There was a problem removing the application', 'error');
    DeleteApplicationModal.close();
  };

  RecruitingPage.prototype.refresh = function () {
    var $secondToLastRound = $('.application-group:nth-last-child(2)');
    var id = this.createInterviewRound($secondToLastRound);
    this.rounds[id].updateCards();
  };

  RecruitingPage.prototype.refreshCharts = function () {
    if (!$('#interview-rounds-graph').length) {
      return;
    }
    /* eslint dot-notation: 0 */
    var chart = Chartkick.charts['interview-rounds-graph'];
    chart.refreshData();
  };

  RecruitingPage.prototype.setupCharts = function () {
    if (!$('#interview-rounds-graph').length) {
      return;
    }
    var $metricsGroup = $('.metrics-group');
    var url = $metricsGroup.data('chart-url');
    this.charts = {};
    this.charts.interviewRounds = new Chartkick.PieChart(
      'interview-rounds-graph',
      url,
      {},
    );
  };

  RecruitingPage.prototype.init = function () {
    EmailModal.init();
    DeleteApplicationModal.init({
      onDeleteApplicationSuccess:
        this.handleDeleteApplicationSuccess.bind(this),
      onDeleteApplicationFail: this.handleDeleteApplicationFail.bind(this),
    });
    this.$container = $(this.container);
    this.setupCharts();
    this.setupEmailButton();
    this.setupDeleteApplicationButton();
    this.setupInterviewRoundButton();
    this.setupVentureDropdown();
  };

  sparkxyz.RecruitingPage = RecruitingPage;
  sparkxyz.EmailModal = EmailModal;

  $(function () {
    recruitingPage = new RecruitingPage();
    recruitingPage.init();
    sparkxyz.RecruitingPage = recruitingPage;
  });
})(window.sparkxyz);
