import { Controller } from '@hotwired/stimulus';
import Snackbar from 'node-snackbar';
import { ERROR_COLOR } from '../constants/colors';

export default class extends Controller {
  static values = { section: String, actionEvent: String };

  onLoadSuccess(event) {
    event.stopPropagation();
    const [, , xhr] = event.detail;

    console.log('remote link');
    console.log('remote-link event: ', this.eventName);

    document.dispatchEvent(
      new CustomEvent(this.eventName, { detail: xhr.response }),
    );
  }

  onLoadFail(e) {
    e.stopPropagation();
    Snackbar.show({
      text: 'There was a problem showing the content.',
      actionTextColor: ERROR_COLOR,
    });
  }

  get action() {
    return this.actionEventValue || 'updated';
  }

  get eventName() {
    return `${this.sectionValue}:${this.action}`;
  }
}
