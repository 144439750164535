/* global window */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['content']

  connect() {
    window.sparkxyz = window.sparkxyz || {};
    window.sparkxyz.modal = this;

    this.$element = $(this.element);
    this.$contentTarget = $(this.contentTarget);
  }

  setContent(content) {
    this.$contentTarget.html(content);
    this.open();
  }

  open() {
    this.$element.foundation('open');
  }

  close() {
    this.$element.foundation('close');
  }
}
