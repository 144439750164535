import { Controller } from '@hotwired/stimulus';

const MODAL_ID = 'competition-apply-modal';

export default class extends Controller {
  onApplyNow() {

  }

  onApplicationLoaded(e) {
    console.log(e);
    const [
      data,
      status,
      xhr,
    ] = e.detail;

    document.dispatchEvent(
      new CustomEvent(
        `${MODAL_ID}-update`,
        {
          detail: xhr.response,
        },
      ),
    );

    document.dispatchEvent(
      new CustomEvent(
        `${MODAL_ID}-open`,
        {},
      ),
    );
  }
}
