import { Controller } from '@hotwired/stimulus';
import { enter, leave } from 'el-transition';

export default class extends Controller {
  static targets = ['backdrop', 'content', 'dialog'];

  static values = {
    isOpen: Boolean,
    static: Boolean,
    clearContentOnClose: Boolean,
  };

  connect() {
    if (this.isOpen) {
      this.open();
    }

    document.addEventListener(`${this.elementId}:close`, this.close);
    document.addEventListener(`${this.elementId}-close`, this.close);
    document.addEventListener(`${this.elementId}-open`, this.open);
    document.addEventListener(`${this.elementId}-update`, this.updateContent);
    document.addEventListener(`${this.elementId}:updated`, this.updateContent);
  }

  disconnect() {
    document.removeEventListener(`${this.elementId}:close`, this.close);
    document.removeEventListener(`${this.elementId}-close`, this.updateContent);
    document.addEventListener(`${this.elementId}-open`, this.open);
    document.removeEventListener(
      `${this.elementId}-update`,
      this.updateContent,
    );
    document.removeEventListener(
      `${this.elementId}:updated`,
      this.updateContent,
    );
  }

  open() {
    if (!this.element) {
      return;
    }
    console.log(this.element);
    document.dispatchEvent(
      new CustomEvent(`${this.elementId}:opening`, {
        detail: {},
      }),
    );
    this.element.classList.remove('hidden');
    enter(this.backdropTarget);
    enter(this.dialogTarget);
  }

  close = () => {
    if (!this.element) {
      return;
    }
    Promise.all([leave(this.backdropTarget), leave(this.dialogTarget)]).then(
      () => {
        this.element.classList.add('hidden');
        this.cleanup();
        document.dispatchEvent(
          new CustomEvent(`${this.elementId}:closed`, {
            detail: {},
          }),
        );
      },
    );
  };

  cleanup() {
    if (this.clearContentOnClose && this.hasContentTarget) {
      this.contentTarget.innerHTML = '';
    }
  }

  backdropClose = (e) => {
    if (this.static) {
      return;
    }
    if (e?.target?.contains(this.dialogTarget)) {
      this.close();
    }
  };

  escClose(event) {
    if (this.static) {
      return;
    }

    if (event.keyCode === 27) {
      this.close();
    }
  }

  updateContent = (event) => {
    if (event && event.detail) {
      this.contentTarget.innerHTML = event.detail;
      this.open();
    }
  };

  get elementId() {
    return this.element.id;
  }

  get clearContentOnClose() {
    if (typeof this.isOpenValue === 'undefined' || this.isOpenValue === null) {
      return true;
    }

    return this.clearContentOnCloseValue;
  }

  get static() {
    if (typeof this.isOpenValue === 'undefined' || this.isOpenValue === null) {
      return false;
    }
    return this.staticValue;
  }

  get isOpen() {
    if (typeof this.isOpenValue === 'undefined' || this.isOpenValue === null) {
      return false;
    }

    return this.isOpenValue;
  }
}
