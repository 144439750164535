import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    $('.send-message-button').on('click', this.onSend);
    $('.open-feedback-modal').on('click', this.onOpenModal);
    $('#feedback_modal_form').on('submit', this.onSubmit);
  }

  disconnect() {
    $('.send-message-button').off('click', this.onSend);
    $('.open-feedback-modal').off('click', this.onOpenModal);
    $('#feedback_modal_form').off('submit', this.onSubmit);
  }

  onSend = (e) => {
    e.preventDefault();
    $('.feedback__send').toggleClass('hide');
    $('.feedback__thanks').toggleClass('hide');
    $('#feedback-modal').foundation('close');
    return false;
  }

  onOpenModal = () => {
    $('#feedback-modal').foundation('open');
  }

  onSubmit(e) {
    e.preventDefault();

    $.post($(this).attr('action'), $(this).serialize(), () => {
      $('#feedback_message').val('');
      $('.feedback__send').toggleClass('hide');
      $('.feedback__thanks').toggleClass('hide');
    })
      .fail((error) => {
        console.error(error);
      });
  }
}
