import { Controller } from '@hotwired/stimulus';

import { ERROR_COLOR } from '../constants/colors';

export default class extends Controller {
  onSendSuccess() {
    Snackbar.show({ text: 'Your invite has been send!' });
    const modal = $('#share-modal');
    setTimeout(() => {
      modal.foundation('close');
    }, 0);
  }

  onSendFail() {
    Snackbar.show({ text: 'There was a problem sharing this venture', actionTextColor: ERROR_COLOR });
  }
}
