import Dropdown from 'stimulus-dropdown';
import { computePosition } from '@floating-ui/dom';

export default class extends Dropdown {
  static targets = ['trigger'];

  connect() {
    super.connect();
  }

  toggle(event) {
    computePosition(this.triggerTarget || this.element, this.menuTarget).then(
      ({ x, y }) => {
        Object.assign(this.menuTarget.style, {
          left: `${x}px`,
          top: `${y}px`,
        });
      },
    );
    super.toggle(event);
  }

  hide(event) {
    super.hide(event);
  }
}
